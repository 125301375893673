/* Styles for countries and world map */

.scale1 svg {
    transform: scale(1.1);
}

.scale4 svg {
    transform: scale(1.4);
}

.scale21 svg {
    transform: scale(2.1);
}

.scale23 svg {
    transform: scale(2.3);
}