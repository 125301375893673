/* remove padding in card extra */
.card-extra-remove-padding .ant-card-extra {
    padding: 0;
}




/* Table */
.ant-table-pagination {
    /* border-top: 1px solid #eee; */
    padding: 0 16px;
    margin: 0;
    float: none;
    text-align: right;
    margin-right: 20px !important;
}

.table-header {
    display: flex;
    align-items: center;
}

.table-actions {
    width: 260px;
}

.table-actions li {
    width: 260px !important;
}

.ant-pagination-options{
    display: none !important;
}




/* List */
.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-unstyled>li {
    margin-bottom: 0.75em;
}

.list-wrapper>.items:last-child {
    margin-bottom: 12px;
}




/* Form */
.antd-form-left-label .ant-form-item-label {
    text-align: left !important;
}

/* Tabs */
 .ant-tabs-ink-bar {
     display: none;
 }

 /* Card */
 .ant-card{
    padding: 16px !important;
 }
 .inno-card-title {
     position: absolute;
     left: 20px;
     top: 20px;
     right: 20px;
     color: #fff;
     font-size: 20px;
     font-weight: 700;
     margin: 0;
     line-height: 23px;
     text-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
     height: 83px;
     width: 90%;
     overflow: hidden;
     text-overflow: ellipsis;
     display: flex;
     align-items: center;
 }

 .inno-card-footer {
     text-align: center;
     padding: 10px;
 }

 .inno-card-bottom-overlay {
     position: absolute;
     left: 0;
     bottom: 0;
     opacity: 0;
     width: 100%;
     background: #fff;
     padding: 10px;
     overflow: hidden;
     -webkit-transition: opacity 0.3s;
     transition: opacity 0.3s;
 }

 .inno-card-bottom-overlay .actionBtnsLeft {
     margin: 0 0 0 -62px;
     -webkit-transition: margin 0.3s;
     transition: margin 0.3s;
     float: left;
 }

 .inno-card-bottom-overlay .actionBtnsRight {
     margin: 0 -130px 0 0;
     -webkit-transition: margin 0.3s;
     transition: margin 0.3s;
     float: right;
 }

 .inno-card-tag {
     position: absolute;
     top: 10px;
     right: 7px;
     z-index: 1;
     border-radius: 0px 0px 0px 4px;
 }

 .inno-card .ant-card-body>div:first-child span {
     height: 50px;
 }

 .inno-card .ant-card-body-label {
     font-size: 14px;
 }

 .inno-card .ant-card-body-value {
     font-weight: 500;
     margin-bottom: 16px;
 }

 .inno-card .inno-card-overlay {
     opacity: 0;
 }

 .inno-card:hover .ant-card-body .inno-card-bottom-overlay {
     opacity: 1;
     background: #fff;
 }

 .inno-card:hover .ant-card-body .inno-card-bottom-overlay .actionBtnsLeft {
     margin: 0;
 }

 .inno-card:hover .ant-card-body .inno-card-bottom-overlay .actionBtnsRight {
     margin: 0;
 }

 .inno-card:hover .inno-card-overlay {
     opacity: 1;
     -webkit-transition: all 0.5s ease-in-out;
     -moz-transition: all 0.5s ease-in-out;
     -o-transition: all 0.5s ease-in-out;
     transition: all 0.5s ease-in-out;
 }

 .card-props .card-item:nth-child(2) {
     flex: 1 !important;
 }

 .card-prop {
     box-shadow: none !important;
     border: 1px solid #e7ecf1 !important;
     transition: box-shadow 0.3s, -webkit-box-shadow 0.3s !important;
 }

 .card-prop .btn-dropdown-action :hover {
     display: flex !important;
 }

 .card-prop:hover {
     box-shadow: 0 9px 31px rgba(0, 0, 0, 0.15) !important;
 }

 .card-prop:hover .btn-dropdown-action {
     display: flex !important;
 }

 .card-prop .flags {
     position: absolute !important;
     left: 0 !important;
     top: 0 !important;
     padding: 6px 8px !important;
     background: #e7ecf1 !important;
     border-bottom-right-radius: 16px !important;
 }

 .card-prop .flags img {
     margin-right: 5px !important;
 }

 .card-actions .ant-card-extra {
     padding: 0;
 }

 .card-space-0>.ant-card-head {
     padding-left: 0px !important;
     padding-right: 0px !important;
 }

 .card-space-0>.ant-card-body {
     padding: 0px !important;
 }
 .card-space-24>.ant-card-head {
     padding-left: 24px !important;
     padding-right: 24px !important;
 }

 .card-space-24>.ant-card-body {
     padding: 24px !important;
 }
 .card-space-h-16>.ant-card-head {
     padding-left: 16px !important;
     padding-right: 16px !important;
 }

 .ant-card-body-label {
     font-size: 12px;
 }

 .ant-card-body-value {
     font-weight: 600;
     margin-bottom: 16px;
 }

 .card {
     overflow: hidden !important;
     margin-bottom: 24px !important;
     padding: 20px !important;
 }

 .card-space-default>.ant-card-body {
     padding: 1.25em;
 }

 .card .ant-card-head {
     margin-bottom: 0 !important;
 }

 .card-space-0 {
     padding: 0 !important;
 }

 .card-space-0 .ant-card-head {
     margin-top: 20px;
 }



 /* Radio */
 .customRadioButtonNoSelected .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
     display: none !important;
 }

 .customRadioButtonNoSelected .yesOption {
     background-color: #eee;
     border-radius: 30px !important;
     padding-right: 38px;
 }

 .customRadioButtonNoSelected .noOption {
     background: #0b78ff !important;
     border-color: #0b78ff !important;
     color: #fff !important;
     border-radius: 30px !important;
     position: relative;
     left: -22px !important;
 }

 .customRadioButtonYesSelected .yesOption {
     background: #0b78ff !important;
     border-color: #0b78ff !important;
     color: #fff !important;
     border-radius: 30px !important;
 }

 .customRadioButtonYesSelected .noOption {
     border-radius: 30px !important;
     background-color: #eee;
     padding-left: 38px;
     position: relative;
     left: -22px !important;
 }


 /* Input Fields */
 .hideNumberPointers .ant-input-number:hover .ant-input-number-handler-wrap {
     display: none !important;
 }

 .hideNumberPointers .ant-input-number-handler-wrap {
     display: none !important;
 }

.ant-input-search .ant-input-group-addon {
    border: none;
    z-index: 2;
    background: transparent;
    left: -38px !important;
}

.ant-input-search .ant-input-group-addon button {
    border: none;
    background: transparent;
    margin-top: -2px;
}



 /* Buttons */
 .search-bar .btn-invite {
     margin: 0 10px;
 }



 /* Badge */
 .bg-badge-success sup {
     background: #47c144 !important;
     border-color: #47c144 !important;
     color: #fff !important;
 }

 .bg-badge-primary sup {
     background: #0b78ff !important;
     border-color: #0b78ff !important;
     color: #fff !important;
 }

 .bg-badge-warning sup {
     background: #f7a918 !important;
     border-color: #f7a918 !important;
     color: #fff !important;
 }

 .bg-badge-mid-warning sup {
     background: #fbd793 !important;
     border-color: #fbd793 !important;
 }

 .bg-badge-dark sup {
     background: #373737 !important;
     border-color: #373737 !important;
     color: #fff !important;
 }