/* Pinned component pin style */
.list-container .pinned,
.general-container .pinned,
.child-tab-container .pinned,
.tab-container .pinned {
    -ms-transform: rotate(90deg);
    /* IE 9 */
    -webkit-transform: rotate(90deg);
    /* Chrome, Safari, Opera */
    transform: rotate(90deg);
    color: #0b78ff;
}

.pin-menu-option-dropdown {
    width: 300px;
    left: 1100px;
}