.disabled-class,
.Visible-Disabled .ant-btn .ant-btn-primary .bg-success,
.Visible-Disabled a button {
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
}

.Visible-Disabled {
    cursor: not-allowed;
    color: #666666 !important;
}

.Visible-Disabled:active {
    pointer-events: none;
}