/* Common styles for all components and pages */
body {
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
        sans-serif !important;
}

label {
    display: block;
}

.hand {
    cursor: pointer;
}





/* Text */


.text-uppercase {
    text-transform: uppercase;
}

.text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold;
}




/* Display */

.display-none {
    display: none !important;
}

/* Flex */
.flex-align-normal {
    align-items: normal;

}

/* Backgrounds */
.bg-f9f9f9 {
    background-color: #f9f9f9 !important;
}
.bg-primary {
    background: #0b78ff !important;
    border-color: #0b78ff !important;
    color: #fff !important;
}

.bg-success {
    background: #47c144 !important;
    border-color: #47c144 !important;
    color: #fff !important;
}

.bg-warning {
    background: #f7a918 !important;
    border-color: #f7a918 !important;
    color: #fff !important;
}

.bg-info {
    background: #3e94ff !important;
    border-color: #3e94ff !important;
    color: #fff !important;
}

.bg-danger {
    background: #e6413d !important;
    border-color: #e6413d !important;
    color: #fff !important;
}

.bg-disabled {
    background: #ddd !important;
    border-color: #ddd !important;
    color: #fff !important;
}

.bg-white {
    background: #fff !important;
    border-color: #fff !important;
    color: #555 !important;
}

.bg-table-warning {
    background: #fef6f6 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-table-warning:hover {
    background: #fef6f6 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-table-warning td {
    background: #fef6f6 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-table-warning td:hover {
    background: #fef6f6 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-table-success {
    background: #f1faf0 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-table-success:hover {
    background: #f1faf0 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-table-success td {
    background: #f1faf0 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-table-success td:hover {
    background: #f1faf0 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-list-warning {
    background: #fef6f6 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-list-warning:hover {
    background: #fef6f6 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-list-success {
    background: #f1faf0 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-list-success:hover {
    background: #f1faf0 !important;
    border-color: #fff !important;
    color: #000 !important;
}

.bg-999 {
    background-color: #999;
}

.bg-eee {
    background-color: #eeeeee;
}

.bg-e5e5 {
    background-color: #e5e5e5 !important;
}

.bg-default {
    background: #f9f9f9 !important;
    border-color: #f9f9f9 !important;
    color: #373737 !important;
}

.bg-default:hover {
    border-color: #0b78ff !important;
}

.bg-purple {
    background: #9c7Bf3 !important;
    border-color: #9c7Bf3 !important;
    color: #fff !important;
}



/* Colors */
.color-primary {
    color: #0b78ff !important;
}

.color-danger {
    color: #e6413d !important;
}

.color-dark {
    color: #000 !important;
}

.color-navy {
    color: #585656 !important;
}

.color-warning {
    color: #f7a918 !important;
}

.color-success {
    color: #47c144 !important;
}

.color-info {
    color: #3e94ff !important;
}

.color-neutral {
    color: #555 !important;
}

.color-disabled {
    color: #999999 !important;
}





/* Shadow */
.shadow-s-0 {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}

.shadow-em {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08) !important;
}

.shadow-em:hover {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1) !important;
}




/* Border */
.border-0 {
    border: 0 !important;
}

.border-solid {
    border-style: solid;
}


/* Empty content */
.empty-content {
    margin: 10% auto;
    width: 70%;
    padding: 10px;
}



/* Position */
.position-absolute {
    position: absolute !important;
}

.position-absolute-top {
    position: absolute !important;
    top: 0 !important;
}

.position-absolute-top-left {
    left: 0 !important;
}

.position-absolute-top-right {
    right: 0 !important;
}

.position-absolute-top-10 {
    top: 10px !important;
}

.position-absolute-top-60 {
    top: 60px !important;
}

.position-absolute-bottom {
    position: absolute !important;
    bottom: 0 !important;
}

.position-absolute-bottom-left {
    left: 0 !important;
}

.position-absolute-bottom-right {
    right: 0 !important;
}

.position-absolute-right-12 {
    right: 12px !important;
}

.position-relative {
    position: relative !important;
}
