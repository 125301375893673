::selection {
    background: #0b78ff;
}

.ant-list-item.active {
    background-color: #e1dcdc;
}

.onFocus,
.ant-select-selection:hover,
.ant-select-selection:focus,
.ant-input:focus,
.ant-input-number:hover,
.ant-input-number:focus {
    border-color: #0b78ff;
    box-shadow: 0 0 0 2px rgba(11, 120, 255, 0.3);
}

.ant-typography a {
    color: #0b78ff;
}

.ant-typography a:hover {
    color: #0060d7;
}

.ant-select-selection {
    font-weight: 400;
    font-size: 14px;
}

.ant-select-dropdown-menu-item:hover {
    background-color: #71b0ff;
}

.ant-select-dropdown-menu-item-active {
    background-color: #71b0ff;
}

.ant-btn {
    color: #373737;
    box-shadow: none;
}

.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
    border-color: #ddd;
    color: #0b78ff;
}

.ant-btn-primary {
    background-color: #0b78ff;
    border-color: #0b78ff;
    color: #fff;
}

.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
    color: #fff !important;
    background-color: #0060d7 !important;
    border-color: #0060d7 !important;
}

.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
}

.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled::selection,
.ant-btn-primary.disabled::selection,
.ant-btn-primary[disabled]::selection {
    background-color: #ddd !important;
    border-color: #ddd !important;
    color: rgba(0, 0, 0, 0.25) !important;
}

.ant-btn-success {
    background-color: #47c144 !important;
    border-color: #47c144 !important;
    color: #fff !important;
}

.ant-btn-success:hover,
.ant-btn-success:active,
.ant-btn-success:focus {
    background-color: #379e34 !important;
    border-color: #379e34 !important;
    color: #fff !important;
}

.ant-btn-info {
    background-color: #3e94ff !important;
    border-color: #3e94ff !important;
    color: #fff !important;
}

.ant-btn-info:hover,
.ant-btn-info:active,
.ant-btn-info:focus {
    background-color: #0b78ff !important;
    border-color: #0b78ff !important;
    color: #fff !important;
}

.ant-btn-warning {
    background-color: #f7a918 !important;
    border-color: #f7a918 !important;
    color: #fff !important;
}

.ant-btn-warning:hover,
.ant-btn-warning:active,
.ant-btn-warning:focus {
    background-color: #d58d07 !important;
    border-color: #d58d07 !important;
    color: #fff !important;
}

.ant-btn-danger {
    background-color: #e6413d !important;
    border-color: #e6413d !important;
    color: #fff !important;
}

.ant-btn-danger:hover,
.ant-btn-danger:active,
.ant-btn-danger:focus {
    background-color: #d5201b !important;
    border-color: #d5201b !important;
    color: #fff !important;
}

.ant-btn-default {
    background-color: #ddd;
    border-color: #ddd;
    color: #373737;
}

.ant-btn-default:hover,
.ant-btn-default:active,
.ant-btn-default:focus {
    background-color: #ddd;
    border-color: #ddd;
    color: #0b78ff;
}

.ant-btn-ghost {
    background-color: #ddd !important;
    border-color: #ddd !important;
    color: #373737 !important;
}

.ant-btn-ghost:hover,
.ant-btn-ghost:active,
.ant-btn-ghost:focus {
    border-color: #0b78ff !important;
}

.ant-btn-group {
    display: inline-flex;
}

.ant-btn-group>.ant-btn {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    line-height: 1.44;
    height: 38px;
}

.ant-btn-group>.ant-btn.active {
    color: #fff !important;
    border-color: #0b78ff !important;
    background-color: #0b78ff !important;
}

.ant-btn.disabled,
.ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
}

.ant-card {
    transition: none !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06) !important;
    border: 0 !important;
    padding: 20px;
    color: rgba(0, 0, 0, 0.85) !important;
    border-radius: 12px !important;
}

.ant-card.has-bg {
    background: #f9f9f9;
    box-shadow: none !important;
}

.ant-card-body {
    padding: 0 !important;
}

.ant-card .ant-table-tbody>tr:last-child td {
    border-bottom: 0;
}

.ant-card-head {
    border-bottom: 0 !important;
    padding: 0 !important;
}

.ant-card-head-title {
    padding: 0 !important;
}

.ant-card-action .ant-card-extra {
    color: #0b78ff;
}

.ant-card-action .ant-card-extra:hover {
    color: #0b78ff;
}

.ant-card-action.event .ant-card-head-title {
    margin-right: 8px;
    flex: none;
}

.ant-card-action.event .ant-card-extra {
    float: none;
    margin: 0;
}

.ant-divider-clear {
    background: transparent;
}

.ant-divider-clear-top {
    margin-top: 0;
}

.ant-divider-clear-bot {
    margin-bottom: 0;
}

.ant-tag-action {
    position: absolute;
    top: 10px;
    right: 7px;
    z-index: 1;
    border-radius: 0px 0px 0px 4px;
}

.ant-layout {
    background: transparent !important;
}

.ant-layout-has-sider {
    margin-left: 235px;
}

@media (max-width: 767px) {
    .ant-layout-has-sider {
        margin: 0;
    }
}

.ant-layout-sider,
.ant-layout-header,
.ant-layout-content {
    background-color: #f9f9f9;
}

.ant-layout-content {
    padding: 0 15px;
}

.ant-layout-header {
    background: #f9f9f9 !important;
    align-items: center;
    display: flex;
    height: auto !important;
    line-height: normal !important;
    padding: 0 !important;
}

.ant-layout-header span.title {
    color: #373737;
    margin-right: 8px;
    font-size: 24px;
    line-height: 1;
}

@media (min-width: 992px) {
    .ant-layout-header span.title {
        line-height: normal;
    }
}

.ant-layout-sider-zero-width-trigger {
    background: #0b78ff !important;
    color: #373737;
    top: 12px;
}

.ant-layout-sider-zero-width-trigger:hover {
    background: #0060d7 !important;
}

.ant-pagination li {
    margin-right: 0;
}

.ant-pagination li:first-child a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-pagination li:last-child a {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ant-pagination li a {
    color: #0b78ff;
}

.ant-pagination li a:hover {
    border-color: #ddd;
}

.ant-pagination-item:not(:first-child),
.ant-pagination-item:not(:last-child) {
    border-radius: 0;
}

.ant-pagination-item:hover {
    background-color: #eee;
    border-color: #ddd;
}

.ant-pagination-item.ant-pagination-item-active {
    background-color: #0b78ff;
    border-color: #0b78ff !important;
}

.ant-pagination-item.ant-pagination-item-active a {
    color: #fff !important;
}

.ant-pagination-item.ant-pagination-item {
    border-right: 0;
}

.ant-pagination-item.ant-pagination-item-1 {
    border-left: 0;
}

.ant-pagination-prev a,
.ant-pagination-next a {
    border-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-table-thead>tr>th {
    opacity: 1;
    background: #fff !important;
    background-clip: padding-box !important;
}

.ant-table-thead .ant-table-column-title {
    font-weight: 600;
    color: #555;
}

.ant-table-thead .ant-table-column-sorter-up.on,
.ant-table-thead .ant-table-column-sorter-down.on {
    color: #0b78ff !important;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: #f3f4f6 !important;
    cursor: pointer;
}

.ant-table-tbody>tr td {
    padding: 12px 16px;
    border-bottom-color: #ebf0f4 !important;
}

.ant-switch {
    background-color: #a6a6a6;
}

.ant-switch-checked {
    background-color: #0b78ff;
}

.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 20px) !important;
}

.ant-switch:focus {
    box-shadow: 0 0 0 2px rgba(11, 120, 255, 0.2);
}

.ant-modal-title {
    font-weight: 600;
    line-height: 32px !important;
}

@media (min-width: 576px) {
    .ant-modal-sm.ant-modal {
        max-width: 500px;
    }
}

@media (min-width: 992px) {

    .ant-modal-md.ant-modal,
    .ant-modal-lg.ant-modal {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .ant-modal-lg.ant-modal {
        max-width: 1140px;
    }
}

.ant-input {
    height: 36px;
}

.ant-input:hover {
    color: #0b78ff;
    border-color: #0b78ff;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #0b78ff;
}

.ant-form-explain {
    margin-top: 5px;
}

.ant-form-item-label-normal .ant-form-item-label {
    line-height: normal !important;
}

.ant-form-item-label-normal.mark-right .ant-form-item-label label:before {
    display: none;
}

.ant-form-item-label-normal.mark-right .ant-form-item-label label:after {
    display: inline-block;
    content: "*";
    line-height: 1;
    font-size: 14px;
    color: #f5222d;
    font-family: SimSun, sans-serif, Helvetica, Arial;
    margin-left: 4px;
    margin-right: 0;
}

.ant-form-item-label-normal .ant-input {
    padding: 8px 16px;
    height: 38px;
}

.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #0b78ff;
}

.ant-checkbox:hover .ant-checkbox-inner {
    border-color: #0b78ff;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0b78ff;
    border-color: #0b78ff;
}

.ant-checkbox-checked:after {
    border-color: #0b78ff;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #0b78ff;
}

.ant-tabs-tab-active {
    color: #0b78ff !important;
}

.ant-tabs-tab:hover,
.ant-tabs-tab:active,
.ant-tabs-tab:focus {
    color: #0060d7 !important;
}

.ant-tabs-ink-bar {
    background-color: #0b78ff !important;
}

.ant-collapse,
.ant-collapse>.ant-collapse-item,
.ant-collapse>.ant-collapse-item:last-child {
    border-radius: 4px !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06) !important;
    border: 0;
}

.ant-collapse.has-bg .ant-collapse-item,
.ant-collapse>.ant-collapse-item.has-bg .ant-collapse-item,
.ant-collapse>.ant-collapse-item>.ant-collapse-item.has-bg .ant-collapse-item,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-item.has-bg .ant-collapse-item,
.ant-collapse>.ant-collapse-item:last-child.has-bg .ant-collapse-item,
.ant-collapse>.ant-collapse-item>.ant-collapse-item:last-child.has-bg .ant-collapse-item,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-item:last-child.has-bg .ant-collapse-item {
    background: #f9f9f9;
    box-shadow: none !important;
}

.ant-collapse>.ant-collapse-item,
.ant-collapse>.ant-collapse-item>.ant-collapse-item,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-item,
.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-item:last-child {
    overflow: hidden;
}