/* Screen layout for account activation */

.template-nothing {
    background: linear-gradient(0deg, #f9f9f9 30%, #c5ddff 100%);
}

.application-logo a {
    fill: #0b78ff;
}

#particle-container {
    position: relative;
}

.particle {
    position: absolute;
    border-radius: 50%;
}

@keyframes particle-animation-1 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(1) {
    animation: particle-animation-1 15s infinite;
    opacity: 0.01;
    height: 12px;
    width: 12px;
    animation-delay: -0.4s;
    background: #d326d9;
    top: 52vh;
    left: 26vw;
}

.particle-img:nth-child(1) {
    height: 46px !important;
    width: 46px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-2 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(2) {
    animation: particle-animation-2 15s infinite;
    opacity: 0.75;
    height: 15px;
    width: 15px;
    animation-delay: -0.8s;
    background: #5626d9;
    top: 3vh;
    left: 89vw;
}

.particle-img:nth-child(2) {
    height: 47px !important;
    width: 47px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-3 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(3) {
    animation: particle-animation-3 15s infinite;
    opacity: 0.27;
    height: 12px;
    width: 12px;
    animation-delay: -1.2s;
    background: #262fd9;
    top: 11vh;
    left: 31vw;
}

.particle-img:nth-child(3) {
    height: 48px !important;
    width: 48px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-4 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(4) {
    animation: particle-animation-4 15s infinite;
    opacity: 0.46;
    height: 13px;
    width: 13px;
    animation-delay: -1.6s;
    background: #d93b26;
    top: 18vh;
    left: 50vw;
}

.particle-img:nth-child(4) {
    height: 49px !important;
    width: 49px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-5 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(5) {
    animation: particle-animation-5 15s infinite;
    opacity: 0.32;
    height: 15px;
    width: 15px;
    animation-delay: -2s;
    background: #d9b826;
    top: 33vh;
    left: 57vw;
}

.particle-img:nth-child(5) {
    height: 50px !important;
    width: 50px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-6 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(6) {
    animation: particle-animation-6 15s infinite;
    opacity: 0.27;
    height: 15px;
    width: 15px;
    animation-delay: -2.4s;
    background: #8826d9;
    top: 40vh;
    left: 39vw;
}

.particle-img:nth-child(6) {
    height: 51px !important;
    width: 51px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-7 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(7) {
    animation: particle-animation-7 15s infinite;
    opacity: 0.87;
    height: 12px;
    width: 12px;
    animation-delay: -2.8s;
    background: #d9268e;
    top: 37vh;
    left: 47vw;
}

.particle-img:nth-child(7) {
    height: 52px !important;
    width: 52px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-8 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(8) {
    animation: particle-animation-8 15s infinite;
    opacity: 0.51;
    height: 14px;
    width: 14px;
    animation-delay: -3.2s;
    background: #d92629;
    top: 52vh;
    left: 75vw;
}

.particle-img:nth-child(8) {
    height: 53px !important;
    width: 53px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-9 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(9) {
    animation: particle-animation-9 15s infinite;
    opacity: 0.04;
    height: 12px;
    width: 12px;
    animation-delay: -3.6s;
    background: #2647d9;
    top: 63vh;
    left: 5vw;
}

.particle-img:nth-child(9) {
    height: 54px !important;
    width: 54px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-10 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(10) {
    animation: particle-animation-10 15s infinite;
    opacity: 0.91;
    height: 13px;
    width: 13px;
    animation-delay: -4s;
    background: #d926c1;
    top: 43vh;
    left: 72vw;
}

.particle-img:nth-child(10) {
    height: 55px !important;
    width: 55px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-11 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(11) {
    animation: particle-animation-11 15s infinite;
    opacity: 0.73;
    height: 15px;
    width: 15px;
    animation-delay: -4.4s;
    background: #41d926;
    top: 81vh;
    left: 34vw;
}

.particle-img:nth-child(11) {
    height: 56px !important;
    width: 56px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-12 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(12) {
    animation: particle-animation-12 15s infinite;
    opacity: 0.94;
    height: 15px;
    width: 15px;
    animation-delay: -4.8s;
    background: #9726d9;
    top: 71vh;
    left: 56vw;
}

.particle-img:nth-child(12) {
    height: 57px !important;
    width: 57px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-13 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(13) {
    animation: particle-animation-13 15s infinite;
    opacity: 0.48;
    height: 15px;
    width: 15px;
    animation-delay: -5.2s;
    background: #d92674;
    top: 43vh;
    left: 17vw;
}

.particle-img:nth-child(13) {
    height: 58px !important;
    width: 58px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-14 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(14) {
    animation: particle-animation-14 15s infinite;
    opacity: 0.57;
    height: 12px;
    width: 12px;
    animation-delay: -5.6s;
    background: #d99d26;
    top: 3vh;
    left: 70vw;
}

.particle-img:nth-child(14) {
    height: 59px !important;
    width: 59px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-15 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(15) {
    animation: particle-animation-15 15s infinite;
    opacity: 0.03;
    height: 11px;
    width: 11px;
    animation-delay: -6s;
    background: #bbd926;
    top: 3vh;
    left: 27vw;
}

.particle-img:nth-child(15) {
    height: 60px !important;
    width: 60px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-16 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(16) {
    animation: particle-animation-16 15s infinite;
    opacity: 0.01;
    height: 12px;
    width: 12px;
    animation-delay: -6.4s;
    background: #d9267a;
    top: 15vh;
    left: 58vw;
}

.particle-img:nth-child(16) {
    height: 61px !important;
    width: 61px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-17 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(17) {
    animation: particle-animation-17 15s infinite;
    opacity: 0.16;
    height: 13px;
    width: 13px;
    animation-delay: -6.8s;
    background: #d97a26;
    top: 76vh;
    left: 77vw;
}

.particle-img:nth-child(17) {
    height: 62px !important;
    width: 62px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-18 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(18) {
    animation: particle-animation-18 15s infinite;
    opacity: 0.23;
    height: 13px;
    width: 13px;
    animation-delay: -7.2s;
    background: #2926d9;
    top: 57vh;
    left: 86vw;
}

.particle-img:nth-child(18) {
    height: 63px !important;
    width: 63px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-19 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(19) {
    animation: particle-animation-19 15s infinite;
    opacity: 0.28;
    height: 13px;
    width: 13px;
    animation-delay: -7.6s;
    background: #d95c26;
    top: 46vh;
    left: 99vw;
}

.particle-img:nth-child(19) {
    height: 64px !important;
    width: 64px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-20 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(20) {
    animation: particle-animation-20 15s infinite;
    opacity: 0.85;
    height: 11px;
    width: 11px;
    animation-delay: -8s;
    background: #b2d926;
    top: 6vh;
    left: 69vw;
}

.particle-img:nth-child(20) {
    height: 65px !important;
    width: 65px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-21 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(21) {
    animation: particle-animation-21 15s infinite;
    opacity: 0.32;
    height: 11px;
    width: 11px;
    animation-delay: -8.4s;
    background: #7d26d9;
    top: 15vh;
    left: 20vw;
}

.particle-img:nth-child(21) {
    height: 66px !important;
    width: 66px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-22 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(22) {
    animation: particle-animation-22 15s infinite;
    opacity: 0.62;
    height: 13px;
    width: 13px;
    animation-delay: -8.8s;
    background: #ac26d9;
    top: 34vh;
    left: 90vw;
}

.particle-img:nth-child(22) {
    height: 67px !important;
    width: 67px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-23 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(23) {
    animation: particle-animation-23 15s infinite;
    opacity: 0.77;
    height: 15px;
    width: 15px;
    animation-delay: -9.2s;
    background: #26d950;
    top: 23vh;
    left: 48vw;
}

.particle-img:nth-child(23) {
    height: 68px !important;
    width: 68px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-24 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(24) {
    animation: particle-animation-24 15s infinite;
    opacity: 0.56;
    height: 11px;
    width: 11px;
    animation-delay: -9.6s;
    background: #6ed926;
    top: 22vh;
    left: 43vw;
}

.particle-img:nth-child(24) {
    height: 69px !important;
    width: 69px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-25 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(25) {
    animation: particle-animation-25 15s infinite;
    opacity: 0.32;
    height: 12px;
    width: 12px;
    animation-delay: -10s;
    background: #80d926;
    top: 7vh;
    left: 7vw;
}

.particle-img:nth-child(25) {
    height: 70px !important;
    width: 70px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-26 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(26) {
    animation: particle-animation-26 15s infinite;
    opacity: 0.17;
    height: 12px;
    width: 12px;
    animation-delay: -10.4s;
    background: #26d9c7;
    top: 97vh;
    left: 76vw;
}

.particle-img:nth-child(26) {
    height: 71px !important;
    width: 71px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-27 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(27) {
    animation: particle-animation-27 15s infinite;
    opacity: 0.79;
    height: 12px;
    width: 12px;
    animation-delay: -10.8s;
    background: #d9b526;
    top: 29vh;
    left: 40vw;
}

.particle-img:nth-child(27) {
    height: 72px !important;
    width: 72px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-28 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(28) {
    animation: particle-animation-28 15s infinite;
    opacity: 0.59;
    height: 12px;
    width: 12px;
    animation-delay: -11.2s;
    background: #268ed9;
    top: 79vh;
    left: 25vw;
}

.particle-img:nth-child(28) {
    height: 73px !important;
    width: 73px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-29 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(29) {
    animation: particle-animation-29 15s infinite;
    opacity: 0.81;
    height: 12px;
    width: 12px;
    animation-delay: -11.6s;
    background: #26d0d9;
    top: 54vh;
    left: 75vw;
}

.particle-img:nth-child(29) {
    height: 74px !important;
    width: 74px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-30 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(30) {
    animation: particle-animation-30 15s infinite;
    opacity: 0.46;
    height: 11px;
    width: 11px;
    animation-delay: -12s;
    background: #94d926;
    top: 37vh;
    left: 80vw;
}

.particle-img:nth-child(30) {
    height: 75px !important;
    width: 75px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-31 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(31) {
    animation: particle-animation-31 15s infinite;
    opacity: 0.92;
    height: 13px;
    width: 13px;
    animation-delay: -12.4s;
    background: #26d97a;
    top: 100vh;
    left: 48vw;
}

.particle-img:nth-child(31) {
    height: 76px !important;
    width: 76px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-32 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(32) {
    animation: particle-animation-32 15s infinite;
    opacity: 0.24;
    height: 13px;
    width: 13px;
    animation-delay: -12.8s;
    background: #2629d9;
    top: 46vh;
    left: 1vw;
}

.particle-img:nth-child(32) {
    height: 77px !important;
    width: 77px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-33 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(33) {
    animation: particle-animation-33 15s infinite;
    opacity: 0.78;
    height: 13px;
    width: 13px;
    animation-delay: -13.2s;
    background: #26d9be;
    top: 42vh;
    left: 64vw;
}

.particle-img:nth-child(33) {
    height: 78px !important;
    width: 78px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-34 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(34) {
    animation: particle-animation-34 15s infinite;
    opacity: 0.96;
    height: 11px;
    width: 11px;
    animation-delay: -13.6s;
    background: #b5d926;
    top: 64vh;
    left: 18vw;
}

.particle-img:nth-child(34) {
    height: 79px !important;
    width: 79px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

@keyframes particle-animation-35 {
    0% {
        transform: translate(0, 0px) scale(0.9);
    }

    30% {
        transform: translate(0, 50px) scale(1);
    }

    60% {
        transform: translate(50px, 0px) scale(1);
    }

    100% {
        transform: translate(0, 0px) scale(0.9);
    }
}

.particle:nth-child(35) {
    animation: particle-animation-35 15s infinite;
    opacity: 0.51;
    height: 15px;
    width: 15px;
    animation-delay: -14s;
    background: #2cd926;
    top: 26vh;
    left: 52vw;
}

.particle-img:nth-child(35) {
    height: 80px !important;
    width: 80px !important;
    opacity: 1 !important;
    transform: translate3d(50vw, 50vh, 0);
}

.panel {
    height: 600px;
    width: 50vw;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: 16px;
    box-shadow: -36px -27px 58px -5px rgba(255, 255, 255, 0), 22px 22px 50px -24px rgba(0, 0, 0, 0.1);
    padding: 3em;
    background: #fff;
    animation: bummer 0.7s alternate ease-in-out;
    transform: scale(0.95, 0.95);
    animation-fill-mode: forwards;
}

@media (max-width: 1440px) {
    .panel {
        height: 600px;
        width: 50vw;
    }
}

@media (max-width: 1200px) {
    .panel {
        height: 600px;
        width: 50vw;
    }
}

@media (max-width: 979px) {
    .panel {
        height: 600px;
        width: 60vw;
    }
}

@media (max-width: 767px) {
    .panel {
        height: 600px;
        width: 80vw;
    }
}

@media (max-width: 640px) {
    .panel {
        height: 600px;
        width: 90vw;
    }
}

.panel h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

.panel img {
    width: 39% !important;
    height: auto !important;
    margin: 0;
}

@-webkit-keyframes bummer {
    100% {
        -webkit-transform: scale(1, 1);
    }
}

@keyframes bummer {
    100% {
        transform: scale(1, 1);
    }
}