body {
  background: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
  color: #373737 !important;
}

body:hover {
  border-color: #0b78ff !important;
}

.ant-profile-uploader {
  margin: 0 auto;
}

.ant-profile-uploader > .ant-upload {
  width: 125px !important;
  height: 125px !important;
  margin: 0 auto;
  border-radius: 50% !important;
}

.ant-profile-uploader > .ant-upload > span {
  border-radius: 50% !important;
  overflow: hidden !important;
}

.ant-spin-spinning {
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  width: 20px !important;
  height: 20px !important;
}

.ant-radio-question-choices {
  width: 100%;
  margin-bottom: 17px !important;
}

.ant-radio-question-choices > div:last-child {
  margin-bottom: 0 !important;
}

.ant-layout {
  background: #f9f9f9 !important;
}

.ant-layout-sider-light {
  background: transparent !important;
}

.ant-layout-sider-zero-width-trigger {
  color: #fff !important;
}

.ant-layout-content {
  padding: 0 !important;
}

@media (min-width: 992px) {
  .ant-layout-main {
    margin-left: 280px !important;
  }
}

.ant-card {
  margin-bottom: 24px !important;
}

.ant-card-header-white .ant-card-head {
  background-color: #fff !important;
}

.ant-card-actions-white .ant-card-actions {
  background-color: #fff !important;
}

.ant-card.actions-bottom .ant-card-actions {
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  border-top: 0 !important;
}

.ant-collapse {
  border: 0 !important;
}

.ant-collapse-item {
  background: #fff !important;
}

.ant-collapse-header {
  background: #fff !important;
}

.ant-collapse-content > .ant-collapse-item {
  box-shadow: 0 0 0 !important;
}

.ant-dropdown-menu-items {
  padding: 0 !important;
}

.ant-dropdown-menu-items .ant-list-item {
  padding: 12px 20px !important;
  border-bottom: 1px solid #ddd;
}

.ant-dropdown-menu-items .ant-list-item:last-of-type {
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.profile-switch-dropdown .ant-dropdown-menu-vertical > li {
  width: 173px !important;
}

.ant-dropdown-menu-vertical {
  padding: 0 !important;
}

.ant-dropdown-menu-vertical > li {
  padding: 8px 15px !important;
  width: 173px;
}

.ant-dropdown .ant-menu {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}

.ant-list.sider-sub-list {
  border-bottom: 1px solid #e8e8e8;
}

.ant-list.sider-sub-list .ant-list-item-meta {
  flex: unset;
}

.ant-list-item-meta-selection .ant-list-item-meta-avatar {
  margin-right: 0 !important;
}

.ant-list-item-meta-selection .ant-list-item-meta-content h4 {
  margin: 0 !important;
}

.ant-modal-document .ant-modal-content {
  border-radius: 0 !important;
  position: fixed;
  overflow-y: scroll;
  bottom: 0 !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
}

.ant-menu-item {
  width: 100% !important;
}

.ant-drawer-handler .ant-drawer-content-wrapper {
  box-shadow: 0 0 5em rgba(0, 0, 0, 0.2) !important;
  background-color: transparent !important;
}

.ant-popover-rightBottom {
  left: 60px !important;
}

.ant-popover-rightBottom .ant-popover-arrow,
.ant-popover-rightBottom .ant-popover-inner {
  border: 1px solid #ddd !important;
}

.ant-popover-rightBottom .ant-popover-arrow-content,
.ant-popover-rightBottom .ant-popover-inner-content {
  width: 270px !important;
  padding: 0 !important;
}

.ant-popover-rightBottom .ant-popover-arrow {
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  background: #fff !important;
}

.ant-steps-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ant-steps-item-icon {
  text-align: left !important;
}

.ant-steps-item-content {
  text-align: left !important;
}

.ant-steps-item-content .ant-steps-item-description {
  font-size: 12px !important;
  text-align: left !important;
  max-width: 100%;
}

.ant-drawer-btn {
  color: #555;
  background-color: #f9f9f9;
  z-index: 1001;
  border: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5em !important;
  transform: rotate(90deg);
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.ant-drawer-btn:hover {
  color: #0b78ff;
}

.border-t-w-3 {
  border-top-width: 3px !important;
}

.border-t-w-4 {
  border-top-width: 4px !important;
}

.border-t-w-5 {
  border-top-width: 5px !important;
}

.border-r-none {
  border-right: none !important;
}

.border-color-danger {
  border-color: #e6413d;
}

.card-upload {
  border-style: dashed;
  border-color: #ddd;
}

.card-upload .ant-card-body .ant-upload {
  display: block;
}

.card-upload .ant-card-body .ant-upload .ant-uploaded {
  background-color: #eee !important;
  opacity: 0.5;
  transition: 0.5s;
}

.card-upload .ant-card-body .ant-upload .ant-uploaded:hover {
  opacity: 1;
}

.card-upload .ant-card-body .ant-upload .ant-uploaded-file {
  opacity: 1;
}

.card-upload .ant-card-body .ant-upload .ant-uploaded-file .ant-upload-icon {
  opacity: 0 !important;
  transition: 0.5s;
}

.card-upload
  .ant-card-body
  .ant-upload
  .ant-uploaded-file:hover
  .ant-upload-icon {
  opacity: 1;
}

.card-upload .ant-card-body .ant-upload .ant-uploaded-file-video,
.card-upload .ant-card-body .ant-upload .ant-uploaded-file-image {
  background-color: transparent !important;
}

.card-upload .ant-card-body .ant-upload .ant-uploaded .ant-upload-icon {
  text-align: center;
  z-index: 1;
}

.header-sub {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: justify-between;
}

.header-sub-wrapper {
  padding-top: 0 !important;
  overflow: hidden;
  border-bottom: 1px solid #eee;
}

.header-bar {
  flex: 1;
}

.header-bar-actions {
  display: flex;
  align-items: center;
}

.header-bar-actions button {
  line-height: 1.44;
  text-align: center;
}

.hand:hover {
  cursor: pointer;
}

.document-editor .ant-modal-content {
  border-radius: 0 !important;
}

#mergeFieldList {
  margin-bottom: 15px !important;
}

#mergeFieldList .ant-collapse {
  box-shadow: none;
  border-radius: 0 !important;
}

#mergeFieldList .ant-collapse-item {
  margin-top: 0 !important;
  border-top: 0 !important;
  border-bottom: 1px solid #e8e8e8 !important;
}

#mergeFieldList .ant-collapse-item:last-child {
  border-bottom: none !important;
}

.backgroundImageCover {
  background-position: center center !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.react-pdf__Page__canvas {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px !important;
  width: 100% !important;
  height: auto !important;
  border-radius: 4px !important;
}

.ant-list-empty-text {
  padding: 0 !important;
}

.score {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.scorenumber {
  width: 80px !important;
  height: 80px !important;
  background: #328cf9;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.btn-disable {
  cursor: not-allowed;
  pointer-events: none;
  color: #c0c0c0 !important;
  background-color: #ffffff;
}

.e-de-tool-ctnr-properties-pane {
  overflow-y: hidden;
}

.ql-editor {
  padding: 0 !important;
}

.ql-editor > *::first-letter {
  text-transform: none;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 15em !important;
  padding: 1em !important;
}

.btn-ghost-link {
  color: #373737 !important;
}

.btn-ghost-link:hover {
  color: #0b78ff !important;
}

.btn-dropdown-group .ant-btn {
  box-shadow: none;
}

.btn-dropdown-group .ant-btn:first-child {
  padding-left: 12px !important;
  padding-right: 12px !important;
  border-right-color: #f6faff !important;
}

.btn-dropdown-group .ant-btn:last-child {
  border-left-color: #f6faff !important;
}

.btn-dropdown-action.ant-btn {
  border: 0 !important;
  box-shadow: none;
}

.btn-dropdown-action.ant-btn.ellipsis svg {
  fill: #999;
}

.btn-dropdown-action.ant-btn:hover {
  background-color: #0b78ff;
}

.btn-dropdown-action.ant-btn:hover.ellipsis svg {
  fill: #fff !important;
}

.btn-dropdown-action.ant-btn:focus {
  background-color: #eee;
}

.btn-dropdown-action.ant-btn:focus.ellipsis svg {
  fill: #888;
}

.btn-dropdown-action.ant-dropdown-open:hover {
  background-color: #0b78ff;
}

.btn-dropdown-action.ant-dropdown-open:hover.ellipsis svg {
  fill: #fff !important;
}

.home-logo a {
  fill: #0b78ff;
  padding: 15px !important;
  display: block;
  text-align: center;
}

.home-logo a.platform {
  position: relative;
  width: 60px !important;
  height: 60px !important;
}

.home-logo a.platform .platform-logo {
  opacity: 1;
  position: absolute;
  top: 15px !important;
  left: 15px !important;
  -webkit-animation: pop linear 1 0.3s;
  animation: pop linear 1 0.3s;
}

.home-logo a.platform .platform-home {
  opacity: 0;
  position: absolute;
  top: 15px !important;
  left: 18px !important;
}

.home-logo a.platform:hover .platform-logo {
  opacity: 0 !important;
  -webkit-transition: all 0.3s cubic-bezier(0.17, 0.67, 0, 1);
  -o-transition: all 0.3s cubic-bezier(0.17, 0.67, 0, 1);
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0, 1);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.home-logo a.platform:hover .platform-home {
  opacity: 1;
  -webkit-animation: pop linear 1 0.3s;
  animation: pop linear 1 0.3s;
}

.home-logo a.active {
  background: #fff !important;
  fill: #0b78ff;
}

.badge {
  padding: 0 !important;
  text-align: center;
  border: 0 !important;
  font-weight: 600 !important;
}

.badge sup {
  line-height: 1;
  height: auto;
  padding: 3px 6px !important;
  box-shadow: none;
}

.badge-warning sup {
  color: #f7a918;
  background-color: #fef2dd;
}

.badge-sky sup {
  color: #009ec2 !important;
  background-color: #8feaff !important;
}

.badge-success sup {
  color: #47c144 !important;
  background-color: #def4dd !important;
}

.badge-draft sup {
  color: #fff !important;
  background-color: #aeaeae !important;
}

.badge-primary sup {
  color: #fff !important;
  background-color: #0b78ff;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.ant-page-header-title-view {
  display: flex;
  justify-content: justify-between;
  align-items: center;
}

.ant-page-header-title-view-extra {
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}

.doc-types .doc-type .doc-type-title {
  height: 45px !important;
}

.doc-types .doc-type .doc-type-footer {
  height: 85px !important;
}

.profile {
  margin-bottom: 16px !important;
}

.profile p {
  margin: 0 !important;
}

.tab-title {
  font-size: 1.2em !important;
  margin-bottom: 0.2em !important;
  text-align: left !important;
}

.tab-description {
  font-size: 0.9em !important;
  text-align: left !important;
  white-space: normal !important;
}

.tab-description-top {
  font-size: 1em !important;
}

.tab-title-top {
  font-size: 1.8em !important;
}

.tab-top .ant-tabs-tab {
  border: 0 !important;
  box-shadow: 0px !important;
}

.tab-top .ant-tabs-tab-active {
  border: 0 !important;
}

.processHeader > [class*="ant-col-"] {
  color: #000 !important;
}

.processHeader > [class*="ant-col-"] span {
  display: block;
}

.processHeader > [class*="ant-col-"] b {
  font-weight: 500 !important;
  font-size: 1.4em !important;
}

.processHeader > [class*="ant-col-"]:last-child {
  color: #e6413d;
}

.defaultListHeader .ant-list-header {
  padding-left: 1em !important;
  font-weight: 700 !important;
  font-size: 1.1em !important;
}

.defaultChartHeight
  .antd-pro-charts-chart-card-chartCard
  .antd-pro-charts-chart-card-meta {
  height: 28px !important;
}

.list-meta-padding .ant-list-item-meta {
  flex: 3 1 !important;
}

.ant-time-picker-panel-select ul {
  padding: 0 0 6px !important;
}

.columnProgressbar
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item-tail {
  display: none !important;
}

.columnProgressbar
  .ant-steps-item-finish
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  display: none !important;
}

.columnProgressbar .ant-steps-item {
  margin-right: 0 !important;
  padding: 0 !important;
  flex: 0 0 15% !important;
}

.columnProgressbar .ant-steps-item .ant-steps-item-icon {
  margin-right: 0 !important;
  height: 26px !important;
}

.columnProgressbar .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
  font-size: 1.1em !important;
}

.columnProgressbar .ant-steps-item .ant-steps-item-title:after {
  left: 0 !important;
  width: 95%;
  margin-left: 0.3em !important;
  display: none;
}

.columnProgressbar .ant-steps-item .steps-title {
  display: none;
}

.columnProgressbar .ant-steps-item-content .ant-steps-item-description {
  display: none !important;
}

.maximumHeight .ant-spin-nested-loading {
  max-height: 440px !important;
  overflow-y: auto;
}

.cutomizedSteps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: #47c144 !important;
}

.cutomizedSteps
  .ant-steps-item-finish
  .ant-steps-item-content
  .ant-steps-item-title {
  color: #47c144 !important;
}

.cutomizedSteps
  .ant-steps-item-finish
  .ant-steps-item-content
  .ant-steps-item-description {
  color: #47c144 !important;
}

td .ant-steps .ant-steps-item {
  display: inline-block;
}

.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #47c144 !important;
}

@media (max-width: 1200px) {
  .ant-steps-item-res {
    display: flex;
    align-items: center;
  }

  .ant-steps-item-res .ant-steps-item-content {
    white-space: normal !important;
  }

  .ant-steps-item-res .ant-steps-item-title {
    font-size: 14px !important;
    line-height: 1.15em !important;
  }

  .ant-steps-item-res .ant-steps-item-title:after {
    top: 8px !important;
  }

  .ant-steps-item-res .ant-steps-item-description {
    display: none;
  }

  .ant-steps-item-res .ant-steps-icon {
    font-size: 1em !important;
  }
}

@media (max-width: 1200px) and (max-width: 979px) {
  .ant-steps-item-res .ant-steps-item-title {
    display: none;
  }
}

@media (max-width: 1200px) and (max-width: 979px) {
  .ant-steps-item-res .ant-steps-icon {
    font-size: 24px !important;
  }
}

.feeViewList .ant-spin-nested-loading {
  height: 280px !important;
  overflow-y: auto;
}

.spaceBetweenListItems .ant-list-item-content-single {
  justify-content: justify-between !important;
}

.xero-table-fixed-height .ant-table-body {
  max-height: 450px !important;
  overflow-y: auto;
}

.modal-padding-0 .ant-modal-body {
  padding: 0 !important;
}

.no-border {
  border: none !important;
}

.module-detail .ant-tabs-content {
  padding-top: 24px !important;
}

.default-card-border-box-shadow {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06) !important;
  border: 0 !important;
  border-radius: 8px !important;
}

.grid-table-option-dropdown .ant-dropdown-menu-vertical li {
  width: 185px !important;
}

.grid-action {
  width: 250px !important;
}

.grid-action li {
  width: 250px !important;
}

.ant-dropdown-menu-item-divider {
  padding: 2px !important;
}

.custom-grid-option .ant-dropdown-menu-vertical {
  width: 192px !important;
}

.custom-grid-option .ant-dropdown-menu-item-group {
  padding-left: 8px !important;
  padding-right: 2px !important;
  margin-right: 2px !important;
}

.custom-grid-option ul li {
  list-style-type: none;
  padding-left: 4px !important;
  padding-right: 0 !important;
}

.custom-grid-option .ant-dropdown-menu-item-divider {
  width: 192px !important;
}

.custom-table-option .ant-dropdown-menu-vertical {
  width: 192px !important;
}

.custom-table-option .ant-dropdown-menu-item-group {
  padding-left: 8px !important;
  padding-right: 2px !important;
  margin-right: 2px !important;
}

.custom-table-option ul li {
  list-style-type: none;
  padding-left: 4px !important;
  padding-right: 0 !important;
}

.spaceBetweenListItems {
  display: flex;
  justify-content: space-between;
}

.invoice-list {
  margin-bottom: 0 !important;
}

.invoice-list .ant-table-footer {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.invoice-list thead th {
  border-bottom: 0 !important;
}

.invoice-list tbody tr {
  padding: 16px !important;
  background: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
  color: #373737 !important;
}

.invoice-list tbody tr:hover {
  border-color: #0b78ff !important;
}

.invoice-list tbody tr:first-of-type td:first-child {
  border-top-left-radius: 12px !important;
}

.invoice-list tbody tr:first-of-type td:last-child {
  border-top-right-radius: 12px !important;
}

.invoice-list tbody tr:last-of-type td:first-child {
  border-bottom-left-radius: 0px !important;
}

.invoice-list tbody tr:last-of-type td:last-child {
  border-bottom-right-radius: 0px !important;
}

.module-basic-details .ant-card-extra {
  padding: 0 !important;
}

.avatar-logo img {
  height: auto;
}

.ant-table-body {
  overflow-x: auto !important;
}

.ant-steps-item-hidden .ant-steps {
  align-items: flex-start;
}

.ant-steps-item-hidden .ant-steps-item-icon {
  display: none;
}

.menu-full-width .ant-menu {
  min-width: 230px !important;
}

.menu-full-width li {
  width: 100% !important;
}

.border-grey {
  border: 1px solid #eee;
}

.ant-layout-has-sider {
  margin-left: 0 !important;
}

.ant-sidebar .ant-menu {
  border-radius: 20px 0 !important;
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
}

.ant-layout-sider {
  max-width: 240px !important;
  margin-right: 24px !important;
}

.ant-menu-item::after {
  border-right: none !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff !important;
}

.ant-layout-content {
  overflow-x: unset !important;
}

.invoice-container {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.table-showing-total tbody tr:last-child {
  font-weight: bold !important;
}

.table-footer-0 .ant-table-footer {
  padding: 12px 0 !important;
}

.vertical-align-top {
  vertical-align: top;
}

.invoice-bg-danger {
  background: #ec6d6a !important;
  border-color: #ec6d6a !important;
  color: #fff !important;
}

.invoice-bg-danger a {
  color: #fff !important;
}

.invoice-bg-danger:hover {
  color: #373737 !important;
}

.invoice-bg-danger:hover a {
  color: #0b78ff !important;
}

.invoice-bg-danger td {
  background: #ec6d6a !important;
  border-color: #ec6d6a !important;
  color: #fff !important;
}

.invoice-bg-danger td a {
  color: #fff !important;
}

.invoice-bg-danger td:hover {
  color: #373737 !important;
}

.invoice-bg-danger td:hover a {
  color: #0b78ff !important;
}

.invoice-bg-light-danger {
  background: #fad7d7 !important;
  border-color: #fad7d7 !important;
  color: #000000ba !important;
}

.invoice-bg-light-danger td {
  background: #fad7d7 !important;
  border-color: #fad7d7 !important;
  color: #000000ba !important;
}

.invoice-bg-light-warning {
  background: #fef2dd !important;
  border-color: #fef2dd !important;
}

.invoice-bg-mid-warning {
  background: #fbd793 !important;
  border-color: #fbd793 !important;
}

.invoice-grid-bg-danger {
  background: #ec6d6a !important;
  border-color: #ec6d6a !important;
}

.invoice-grid-bg-danger:hover {
  color: #373737 !important;
}

.process-table {
  display: flex;
}

.process-table-variance-invoice-description {
  flex: 1 1 25%;
}

.process-table-variance-invoice-previousTaxes {
  flex: 1 1 18%;
  margin-right: -2px !important;
}

.process-table-variance-invoice-previousAmount {
  flex: 1 1 17%;
  padding-right: 3px !important;
}

.process-table-variance-invoice-taxes {
  flex: 1 1 12%;
  padding-right: 2px !important;
}

.process-table-variance-invoice-amount {
  flex: 1 1 16%;
}

.process-table-variance-invoice-variance {
  flex: 1 1 12%;
}

.process-table-variance-invoice-processing-description {
  flex: 1 1 25%;
}

.process-table-variance-invoice-processing-previousTaxes {
  flex: 1 1 15%;
  padding-right: 6px !important;
}

.process-table-variance-invoice-processing-previousAmount {
  flex: 1 1 20%;
  padding-right: 2px !important;
}

.process-table-variance-invoice-processing-taxes {
  flex: 1 1 13%;
  padding-right: 2px !important;
}

.process-table-variance-invoice-processing-amount {
  flex: 1 1 17%;
  margin-right: -4px !important;
}

.process-table-variance-invoice-processing-variance {
  flex: 1 1 12%;
}

.process-table-variance-no-invoice-description {
  flex: 1 1 42%;
}

.process-table-variance-no-invoice-previousAmount {
  flex: 1 1 21%;
}

.process-table-variance-no-invoice-amount {
  flex: 1 1 21%;
}

.process-table-variance-no-invoice-variance {
  flex: 1 1 16%;
}

.process-table-variance-no-invoice-processing-description {
  flex: 1 1 42%;
}

.process-table-variance-no-invoice-processing-previousAmount {
  flex: 1 1 24%;
  padding-right: 3px !important;
}

.process-table-variance-no-invoice-processing-amount {
  flex: 1 1 22%;
  padding-right: 6px !important;
}

.process-table-variance-no-invoice-processing-variance {
  flex: 1 1 16%;
}

.process-table-no-variance-invoice-description {
  flex: 1 1 50%;
}

.process-table-no-variance-invoice-taxes {
  flex: 1 1 26%;
  padding-right: 5px !important;
}

.process-table-no-variance-invoice-amount {
  flex: 1 1 24%;
}

.process-table-no-variance-invoice-processing-description {
  flex: 1 1 50%;
}

.process-table-no-variance-invoice-processing-taxes {
  flex: 1 1 26%;
  padding-right: -2px !important;
}

.process-table-no-variance-invoice-processing-amount {
  flex: 1 1 24%;
}

.process-table-no-variance-no-invoice-processing-description {
  flex: 1 1 50%;
}

.process-table-no-variance-no-invoice-processing-amount {
  flex: 1 1 50%;
}

.module-info-card {
  width: 100px !important;
  height: 100px !important;
  display: "flex";
  align-items: "center";
}

.create-screen-body {
  position: absolute;
  top: 60px !important;
  height: 91vh;
  overflow-y: auto;
}

.form-steps-active {
  border-left: 4px solid;
  border-left-color: #0b78ff;
}

.titles-dashboard {
  font-size: 1.5em !important;
  font-weight: 600 !important;
}

.layout {
  padding-top: 70 !important;
}

.layout-detail-header {
  position: fixed;
  top: 0 !important;
  z-index: 1;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-bottom: 1px solid #e8e8e8;
}

.layout-detail-sider {
  border-right: 1px solid #e8e8e8;
  border-radius: 0 !important;
  position: fixed;
  overflow: auto;
  height: 100vh;
  top: 70 !important;
  left: 0 !important;
}

.layout-detail-layout-expanded {
  margin-left: 410 !important;
}

.layout-detail-layout-collapsed {
  margin-left: 0 !important;
}

.verify-details-input-disabled {
  border: 0 !important;
  color: #555;
  height: auto;
  cursor: default;
  box-shadow: none;
  line-height: normal !important;
  background-color: #fff !important;
}

.verify-details-terms {
  min-height: 18em !important;
  max-height: 18em !important;
  overflow-y: auto;
}

.logo-uploaded {
  max-height: 200px !important;
  width: 90%;
  margin-top: 16px !important;
}

.display-card-title {
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-link {
  border: none;
  background: transparent;
}

.screen-success {
  margin: 10% auto;
  width: 70%;
  padding: 10px !important;
}

.location-search {
  position: absolute;
  width: 600px !important;
  background-color: #fff !important;
  z-index: 3;
  line-height: 2em !important;
}

.location-search-active {
  cursor: pointer;
  background-color: #ccc;
}

.location-search-inactive {
  cursor: pointer;
  background-color: #fafafa;
}

.box-shadow-0 {
  box-shadow: 0 !important;
}

.background-color-transparent {
  background-color: transparent;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.google-chart {
  width: 900px !important;
  height: 500px !important;
}

.icon-container {
  border-radius: 50%;
  background-color: #999;
  width: 25px !important;
  height: 25px !important;
  text-align: center;
}

.radio-vertical {
  display: block;
  height: 30px !important;
  line-height: 30px !important;
}

.word-break {
  word-wrap: break-word;
  word-break: break-all;
}

.word-none {
  word-break: keep-all;
}

.user-authentication {
  width: 300px !important;
}

.user-authentication li {
  width: 300px !important;
}

.default-background {
  background-color: #e5e5e5 !important;
}

.payrun-steps .ant-steps-item-icon {
  text-align: center !important;
}

.payrun-steps .ant-steps-item-description {
  color: #999999 !important;
}

.payrun-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff !important;
}

.payrun-steps .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
  color: #0b78ff !important;
}

.payrun-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #0b78ff !important;
}

.payrun-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #0b78ff !important;
}

.payrun-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: #fff !important;
}

.payrun-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #0b78ff !important;
}

.payrun-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: #999999 !important;
}

.payrun-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #373737 !important;
}

.payrun-steps.is-completed .ant-steps-item-icon {
  text-align: center !important;
}

.payrun-steps.is-completed .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #47c144 !important;
  border-color: #47c144 !important;
}

.payrun-steps.is-completed
  .ant-steps-item-finish
  .ant-steps-item-icon
  .ant-steps-icon {
  color: #fff !important;
}

.payrun-steps.is-completed
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #47c144 !important;
}

.payrun-steps.is-completed
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: #47c144 !important;
}

.payrun-steps.is-completed
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  color: #47c144 !important;
  background-color: #47c144 !important;
}

.payrun-steps.is-completed
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  color: #47c144 !important;
  background-color: #47c144 !important;
}

.ant-modal {
  padding: 32px !important;
}

.ant-modal-header {
  border: none !important;
  text-align: center;
}

.ant-modal-title {
  font-size: 32px !important;
}

.ant-modal-content {
  padding: 32px !important;
  border-radius: 12px !important;
}

.ant-modal-footer {
  text-align: center;
  border: none;
}

.ant-dropdown-button button {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.ant-dropdown-button .ant-btn-icon-only {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.btn-dropdown .ant-btn-default {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.btn-dropdown .ant-dropdown-trigger {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.btn-dropdown-success .ant-btn-default {
  background: #47c144 !important;
  border-color: #47c144 !important;
  color: #fff !important;
}

.btn-dropdown-success .ant-dropdown-trigger {
  background: #47c144 !important;
  border-color: #47c144 !important;
  color: #fff !important;
}

.no-shadow {
  box-shadow: none !important;
}

.invoice-summary .ant-spin-nested-loading {
  padding-left: 4px;
}

.invoice-summary .ant-spin-nested-loading .ant-list-items {
  background: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
  color: #373737 !important;
}

.invoice-summary .ant-list-item {
  border-bottom: 0 !important;
  padding: 6px 0px 6px 16px !important;
}

.invoice-summary .ant-list-footer {
  padding-left: 20px !important;
  padding-right: 0px !important;
}

.invoice-summary .ant-spin-nested-loading .ant-list-items:hover {
  border-color: #0b78ff !important;
}

.invoice-summary .ant-list-header {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.amendment-summary .ant-card-head {
  min-height: 30px !important;
}

.amendment-summary .ant-card-body .maximumHeight {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 8px;
  padding-right: 8px;
}

.amendment-summary .ant-card-body .ant-list-item {
  background: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
  color: #373737 !important;
  margin-bottom: 12px !important;
  border-radius: 12px !important;
  align-items: flex-start;
  border-bottom: 0 !important;
}

.amendment-summary .ant-card-body .ant-list-item:hover {
  border-color: #0b78ff !important;
}

.amendment-summary .ant-list-item {
  border-bottom: 0 !important;
}

.card-radius-top {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.card-radius-bottom {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.transform-90 {
  transform: rotate(90deg) !important;
}

.transform-minus-45 {
  transform: rotate(-45deg) !important;
}

.dashboard-form {
  min-height: 190px !important;
  padding-left: 0 !important;
}

.modal-title-lh-30 .ant-modal-title {
  line-height: 30px !important;
}

.card-with-title .ant-card-body {
  background: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
  color: #373737 !important;
}

.card-with-title .ant-card-body:hover {
  border-color: #0b78ff !important;
}

.filter-radius input {
  border-radius: 20px !important;
}

.user-info-avatar {
  position: relative;
}

.user-info-avatar .upload-icon {
  position: absolute;
  top: 0 !important;
  left: -34px !important;
  width: 100px !important;
  height: 100px !important;
  transition: all 0.3s;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0 !important;
}

.user-info-avatar:hover .upload-icon {
  opacity: 1;
  cursor: pointer;
  left: -34px !important;
}

.user-info-avatar:hover .upload-icon svg {
  color: #fff !important;
}

.user-info {
  position: relative;
}

.user-info .upload-icon {
  width: 100px !important;
  position: absolute;
  top: 0 !important;
  height: 100px !important;
  left: 0 !important;
  background: rgba(0, 0, 0, 0.6);
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0 !important;
  transition: all 0.3s;
  z-index: 1;
}

.user-info .upload-icon i {
  color: #fff !important;
}

.user-info:hover .user-info-avatar {
  opacity: 1;
}

.user-info:hover .upload-icon {
  opacity: 1;
  cursor: pointer;
}

.user-info:hover .upload-icon svg {
  color: #fff !important;
}

.ant-table-content {
  overflow-x: auto !important;
}

.ant-input {
  border-radius: 30px !important;
}

.ant-input-number {
  border-radius: 30px !important;
}

.ant-select-selector {
  border-radius: 30px !important;
}

.ant-picker {
  border-radius: 30px !important;
}

.ant-col {
  width: 100%;
}

.form-label-left .ant-legacy-form-item-label {
  text-align: left !important;
}

.form-label-left .ant-form-item-label {
  text-align: left !important;
}

.ant-popover-buttons button {
  border-radius: 20px !important;
}

.ant-modal-content button {
  border-radius: 20px !important;
}

.ant-tag {
  border-radius: 20px !important;
}

.ant-picker-time-panel-column {
  padding: 0 !important;
}

textarea {
  border-radius: 4px !important;
}

.ant-pagination-options {
  display: none;
}

.defaultFormMargin .ant-legacy-form-item {
  margin-bottom: 16px !important;
}

/* sidebar */

.singleFieldTextBoxFilter input {
  height: 100%;
}

.singleFieldTextBoxFilter button {
  padding-top: 4px !important;
}

.ant-select-multiple .ant-select-selector {
  height: auto !important;
  padding: 0px 16px 0px !important;
}

.ant-picker-dropdown td {
  border: none;
}

.ant-picker-dropdown .ant-picker-cell {
  background-color: #fff !important;
}

.ant-steps-vertical .ant-steps-item-title {
  margin-top: -5px !important;
}

.payroll-users-tab {
  padding-right: 62px !important;
}

.custom-multi-select-dropdown {
  background-color: #fff !important;
}

.custom-multi-select-dropdown .ant-select-item-option-state {
  display: none !important;
}

.custom-multi-select-dropdown .ant-select-item-option-selected {
  background-color: #fff !important;
}

.custom-multi-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff !important;
}

.custom-multi-selectbox .ant-checkbox {
  display: none;
}

.custom-multi-select-box-overflow .ant-select-selection-overflow {
  height: 35px !important;
  overflow-y: auto;
}

.custom-tabs .ant-tabs-nav::before {
  border: none !important;
}

.custom-tabs .ant-badge-count {
  background-color: #dddddd !important;
  color: #585656 !important;
  border-color: #dddddd !important;
}

.custom-tabs .ant-tabs-nav-list {
  transform: translate(0px, 0px);
  border-radius: 30px !important;
  background-color: #eeeeee;
}

.custom-tabs .ant-tabs-nav-list .ant-tabs-tab {
  padding: 8px 16px !important;
}

.custom-tabs .ant-tabs-tab-active {
  background-color: #0b78ff !important;
  border-radius: 30px !important;
}

.custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.user-status {
  position: absolute;
  top: 40%;
  left: 61%;
}

.user-status .ant-badge-status-dot {
  height: 12px !important;
  width: 12px !important;
}

.ant-table {
  border-radius: 12px !important;
}

.ant-input:placeholder-shown {
  opacity: 0.9;
}

.customized-card-header {
  padding-top: 4px !important;
}

.card-body-overflow-hidden .ant-card-body {
  overflow: hidden !important;
}
