.anticon svg {
    vertical-align: baseline;
}

.custom-integration-icon-size {
    height: 16px;
    width: 16px;
}

/* Return to xemplo or Hr icon*/
.returnIcon{
    transform: scaleY(-1);
    font-size: 18px !important;
}