.flex-1-1-5 {
    flex: 1 1 5%;
}

.flex-1-1-6 {
    flex: 1 1 6%;
}

.flex-1-1-8 {
    flex: 1 1 8%;
}

.flex-1-1-9 {
    flex: 1 1 9%;
}

.flex-1-1-10 {
    flex: 1 1 10%;
}

.flex-1-1-11 {
    flex: 1 1 11%;
}

.flex-1-1-12 {
    flex: 1 1 12%;
}

.flex-1-1-14 {
    flex: 1 1 14%;
}

.flex-1-1-15 {
    flex: 1 1 15%;
}

.flex-1-1-16 {
    flex: 1 1 16%;
}

.flex-1-1-18 {
    flex: 1 1 18%;
}

.flex-1-1-20 {
    flex: 1 1 20%;
}

.flex-1-1-22 {
    flex: 1 1 22%;
}

.flex-1-1-28 {
    flex: 1 1 28%;
}

.flex-1-1-30 {
    flex: 1 1 30%;
}

.flex-1-1-38 {
    flex: 1 1 38%;
}

.flex-1-1-44 {
    flex: 1 1 44%;
}

.flex-1-1-45 {
    flex: 1 1 45%;
}

.flex-1-1-50 {
    flex: 1 1 50%;
}

.flex-1-1-55 {
    flex: 1 1 55%;
}

.flex-1-1-56 {
    flex: 1 1 56%;
}

.flex-1-1-60 {
    flex: 1 1 60%;
}

.flex-1-1-82 {
    flex: 1 1 82%;
}

.flex {
    display: flex !important;
}

.flex-start {
    justify-content: flex-start;
}

.flex-1-1 {
    flex: 1 1 100%;
}

.flex-1-15 {
    flex: 1 1 150%;
}

.align-top {
    align-items: top;
}
