.mce-container iframe {
    height: 70vh !important;
}

.email-templates-layout .ant-layout-header {
    background-color: #fff !important;
}

.email-templates-layout .ant-layout-sider {
    background-color: #fff !important;
} 